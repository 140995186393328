import { Button, Col, Modal, Row } from "antd";
import React, { useContext } from "react";

import classes from "./ModalFail.module.css"

import GlobalContext from "../../../contexts/GlobalContext";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ModalSuccess = (props) => {
  const { open,hideModal } = props;
  const {globalData}=useContext(GlobalContext)
  
  return (
    <Modal open={open!==""} onCancel={hideModal}  centered>
        <Row className={classes.ModalFail}>
< ExclamationCircleOutlined className={classes.errorIcon} />
        <span className={classes.errmsg}> {open}</span></Row>
    </Modal>
  );
};

export default ModalSuccess;
