import { Col } from 'antd'
import React from 'react'
import classes from "./Card.module.css"
const Card = (props) => {
    const {card}=props
  return (
    <Col lg={7} md={7}   xs={24} sm={24} className={classes.card}>
      {card.image}
      <span className={classes.label}>{card.label}</span>
      <span className={classes.value}>{card.value}</span>

    </Col>
  )
}

export default Card