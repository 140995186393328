import React, { useContext } from "react";
import { Col } from "antd";
import styles from ".//WarningCard.module.css";
import GlobalContext from "../../contexts/GlobalContext";

const WarningCard = (props) => {
  const { warningTitle, warningContent, warningCardWidth } = props;
  const { brandProperties } = useContext(GlobalContext);
  return (
    <Col {...warningCardWidth} className={styles.cardContainer}>
      <div className={styles.warningContainer}>
        <span
          className={styles.warningTitle}
          style={{ color: brandProperties.secondColor }}
        >
          {warningTitle}
        </span>

        <span
          className={styles.warningContent}
          style={{ color: brandProperties.secondColor }}
        >
          {warningContent}
        </span>
      </div>
    </Col>
  );
};

export default WarningCard;
